<template>
  <b-nav-item-dropdown
    toggle-class="text-dark"
    :text="$t('main_header_menu_btn_lang')"
    right
  >
    <b-dropdown-item
      href="#"
      :active="isLaungeActive('en')"
      active-class="active-lang"
      @click="setLocale('en')"
    >
      <flag iso="us"></flag>
      {{ $t('main_header_menu_btn_en') }}
    </b-dropdown-item>
    <b-dropdown-item
      href="#"
      :active="isLaungeActive('ru')"
      active-class="active-lang"
      @click="setLocale('ru')"
    >
      <flag iso="ru"></flag>
      {{ $t('main_header_menu_btn_ru') }}
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['languageTake'])
  },
  mounted() {
    if (localStorage.getItem('language')) {
      this.$store.dispatch('defineLanguage')
    }
    this.setLocale(this.languageTake)
  },
  methods: {
    ...mapActions(['setLocale', 'changeLanguage']),
    setLocale(locale) {
      import(`../langs/${locale}.json`)
        .then(msgs => {
          localStorage.setItem('language', locale)
          this.$i18n.setLocaleMessage(locale, msgs)
          this.$i18n.locale = locale
        })
        .then(() => this.changeLanguage(locale))
    },
    isLaungeActive(lang) {
      return localStorage.getItem('language') === lang
    }
  }
}
</script>

<style lang="scss" scoped>
li {
  list-style: none;
}
</style>
