<template>
  <section>
    <div class="row vh-100">
      <div class="col-md-6 col-xl-5 info-block">
        <div class="d-flex flex-column justify-content-around h-100">
          <div class="mt-3">
            <div class="d-flex justify-content-lg-start">
              <LanguageSwitcher />
            </div>
            <div class="view">
              <img
                class="img-fluid mx-auto logo"
                :src="logo"
                alt="Herbarium Logo"
              />
            </div>
          </div>
          <div class="mt-3">
            <router-link
              :to="{ name: 'HerbariumGrid' }"
              tag="button"
              class="btn success mb-4"
            >
              {{ $t('welcome_page_view_catalog') }}
            </router-link>
          </div>
          <h5 class="subheading mt-2 px-2">
            {{ $t('welcome_page_info_created') }}
          </h5>
          <section class="py-2">
            <!--            <h5 class="text-center">-->
            <!--              {{ $t('welcome_page_partners') }}-->
            <!--            </h5>-->

            <div class="row pb-3">
              <div class="col-md-6 col-lg-5 offset-lg-1 part-col">
                <div class="view">
                  <img
                    class="img-fluid mx-auto log-col1"
                    :src="sciencesLogo"
                    alt="Sample image"
                  />
                </div>

                <div class="view mt-3">
                  <img
                    class="img-fluid mx-auto log-col2"
                    :src="plateauLogo"
                    alt="Sample image"
                  />
                </div>
              </div>
              <div class="col-md-6 col-lg-5">
                <div class="view">
                  <img
                    class="img-fluid mx-auto log-col1"
                    :src="aucaLogo"
                    alt="Sample image"
                  />
                </div>

                <div class="view">
                  <img
                    class="img-fluid mx-auto mt-3 log-col2"
                    :src="timelyLogo"
                    alt="Sample image"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <div class="col-md-6 col-xl-7 bg-herbarium">
        <div class="view">
          <div class="mask flex-center hm-gradient"></div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import LanguageSwitcher from '@/components/LanguageSwitcher'
import Logo from '@/assets/partners/HerbariumLogo.png'
import SciencesLogo from '@/assets/partners/NationalAcademyOfSciencesLogo.png'
import AucaLogo from '@/assets/partners/AUCALogo.jpg'
import PlateauLogo from '@/assets/partners/pp_logo.png'
import TimelySoftLogo from '@/assets/partners/logoTimelysoft.png'

export default {
  components: { LanguageSwitcher },
  data() {
    return {
      logo: Logo,
      sciencesLogo: SciencesLogo,
      aucaLogo: AucaLogo,
      plateauLogo: PlateauLogo,
      timelyLogo: TimelySoftLogo
    }
  }
}
</script>
<style lang="scss" scoped>
.row {
  margin-right: 0;
  margin-left: 0;
  background: #fff;
}

.logo {
  width: 350px;
  height: 320px;
}

.part-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.log-col1 {
  height: 150px;
}

.log-col2 {
  height: 50px;
}

.bg-herbarium {
  background: url('../assets/login-page/login-bg.svg') center top/cover
    no-repeat;
}
.info-block {
  box-shadow: 7px 5px 16px 4px rgba(0, 0, 0, 0.55);
}

.success {
  color: #ffffff;
}
</style>
